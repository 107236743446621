body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 700px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.ui.primary.button, .ui.primary.buttons .button {
  background-color: #118379 !important;
}

.ui.secondary.button, .ui.secondary.buttons .button {
  background-color: #F2764C;
}

.ui.basic.secondary.button, .ui.basic.secondary.buttons .button {
  color: #F2764C !important;
  background-color: transparent;
  box-shadow: 0 0 0 1px #F2764C inset !important;
}

.ui.form .field {
  margin: 0 !important;
}

.pushable {
  border: none !important;
}

* {
  font-family: 'Montserrat', sans-serif;
}

.ui.modal *, #scroll-chapter-content *, .ql-editor * {
  font-family: 'Helvetica', sans-serif;
}

i.icon {
  font-family: "Icons" !important;
}

i.icon.outline {
  font-family: "outline-icons" !important;
}

html {
  scroll-behavior: smooth;
}

html, body, #root, .App {
  height: 100%;
  background-color: #F4F8FB;
}

//.ui.modal>.header {
//  background-color: #25415C !important;
//  color: #FFB2B2 !important;
//}
.ui.fullscreen.modal {
  right: 0;
}

.ui.active.modal {
  //@media only screen and (max-width: 767px) {
  //  width: 100%;
  //  height: 100%;
  //  max-width: 100% !important;
  //  max-height: 100% !important;
  //  top: 0;
  //  margin-top: 0 !important;
  //  .content {
  //    max-height: calc(100% - 108px) !important;
  //    height: calc(100% - 112px) !important;
  //  }
  //}
}


.menu-popup {
  z-index: 99999999999999999 !important;
  max-width: 228px !important;
}

.swiper-pagination {
  bottom: 0 !important;
}

.confetti-class {
  z-index: 99999999999999999 !important;
}

.account-page-selector-popup {
  padding: 0 !important;
}

.download-selector {
  border: thin solid black;
}

.download-selector-pop {

}

#message-root {
  position: absolute;
  z-index: 99999999999999999 !important;;
  left: 0;
  top: 60px;
  width: 0;
}

.ui.page.dimmer {
  z-index: 2147483646;
}


input[aria-invalid=true] {
  border: thin solid #f7a1a1 !important;
}

.ql-editor {

  p {
    font-size: 16px !important;
  }
}

.ql-snow .ql-formats .ql-picker.ql-header .ql-picker-label[data-value="3"]:before,
.ql-snow .ql-formats .ql-picker.ql-header .ql-picker-options .ql-picker-item[data-value="3"]:before {
  content: "Subheader";
}

.ql-snow .ql-formats .ql-picker.ql-header .ql-picker-label[data-value="2"]:before,
.ql-snow .ql-formats .ql-picker.ql-header .ql-picker-options .ql-picker-item[data-value="2"]:before {
  content: "Header";
}

.ql-snow .ql-formats .ql-picker.ql-header .ql-picker-label[data-value="1"]:before,
.ql-snow .ql-formats .ql-picker.ql-header .ql-picker-options .ql-picker-item[data-value="1"]:before {
  content: "Normal";
}

.ql-snow .ql-picker-label {
  min-width: 106px !important;
}

a {
  &:hover {
    color: rgba(0, 0, 0, 1) !important;
  }
}


.gallery-modal-class {
  bottom: 0;
  height: 90%;

  .content {
    height: 85%;
  }
}

.image-viewer-modal {
  overflow: hidden !important;
  width: 100% !important;
  height: 100% !important;
  left: 0 !important;
  right: 0 !important;
  margin: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  background: transparent;
}

.ui.page.dimmer {
  justify-content: center;
}

.ui.left.overlay.sidebar {
  z-index: 1000;
}

.react-cookie-banner {
  bottom: 0;
  left: 0;
  right: 0;
  height: 300px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: fixed !important;
  background-color: #2f3337;
  color: white;
  max-width: 400px;
  border-radius: 6px;
  margin: 4px;
  padding: 20px;

  .cookie-message {
    flex: 1;
    line-height: 20px;
    margin: 4px;
    margin-bottom: 12px;
  }
}


.react-toast-notifications__container {
  z-index: 999999 !important;
}

.react-toast-notifications__toast__content, .react-toast-notifications__toast__icon-wrapper, .react-toast-notifications__toast__dismiss-button {
  display: flex;
  align-items: center;
}

.react-toast-notifications__toast__icon-wrapper {
  justify-content: center;
}
