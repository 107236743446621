.placeholder-content {
  width: 100%;

  .top-placeholder {
    .ui.placeholder .image.header:after {
      height: 50px !important;
      margin-left: 7em !important;
    }
    .top-image {
      width: 100px;
      height: 100px;
      border-radius: 60px;
    }

    .line1 {
      width: 100%;

      &::after {
        width: 0% !important;
      }
    }
  }

  .center-placeholder {
    .ui.placeholder .image.header:after {
      height: 50px;
      margin-left: 80px;
    }
  }

  .ui.grid>.row>.column {
    display: flex;
    justify-content: center;
  }
}
